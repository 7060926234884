import axios from "axios";
import { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loader from '../Loader'

function AllBookingsTable() {
  const [allBookingsDetails, setAllBookingsDetails] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const allBookings = async function () {
      try {
        const response = await axios.get(
          `${apiUrl}/protected/VARSTRING1/2024/beautyone/1995/allbookings`,
          {
            withCredentials: true,
          }
        );
        setAllBookingsDetails(response.data);
        setLoader(false);
      } catch (err) {
        console.err("I'm error")
        console.log(err);
      }
    };
    allBookings();
  }, []);

  function formatDate(date) {

    const rawDate = new Date(date);
    return `${rawDate.getDate()}/${rawDate.getMonth()}/${rawDate.getFullYear()}`;
  }
  if (loader) return <div><Loader/></div>;
  if(allBookingsDetails.result === "Illegal access") return <h1>Illegal access</h1>
  if(allBookingsDetails.result.length === 0) return <h1>No booking found</h1>
  console.log(allBookingsDetails.result);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><strong>Client Name</strong></TableCell>
            <TableCell align="center"><strong>Client Contact</strong></TableCell>
            <TableCell align="center"><strong>Client Email</strong></TableCell>
            <TableCell align="center"><strong>Booking Date</strong></TableCell>
            <TableCell align="center"><strong>Booking Created Date</strong></TableCell>
            <TableCell align="center"><strong>Client City</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allBookingsDetails.result.map((row) => (
            <TableRow
              key={row.booking_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center">{row.user_name}</TableCell>
              <TableCell align="center">{row.user_contact}</TableCell>
              <TableCell align="center">{row.user_email}</TableCell>
              <TableCell align="center">{formatDate(row.booking_date)}</TableCell>
              <TableCell align="center">{formatDate(row.booking_created_at)}</TableCell>
              <TableCell align="center">{row.user_city}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default AllBookingsTable;
