import "../style/linkbutton.css"
function LinkButton(props) {
  return (
    <div id="button" className="liButton" onClick={props.onClick}>
      <a className="liAnchor" href={props.link}>
        {props.title}
      </a>
    </div>
  );
}
export default LinkButton;
