import "../style/androidlinkbutton.css"
function AndroidLinkButton(props) {
  return (
    <div id="button" className="androidliButton" onClick={props.onClick}>
      <a className="androidliAnchor" href={props.link}>
        {props.title}
      </a>
    </div>
  );
}
export default AndroidLinkButton;
