import Header from "./Header";
import LinkButton from "./LinkButton";
import AndroidLinkButton from "./AndroidLinkButton";
import Footer from "./Footer";

function Welcome() {
  const menuOptionsProps = [
    {
      name: "Sign In",
      address: "/signin",
    },
    {
      name: "Sign Up",
      address: "/signup",
    },
    {
      name: "Services",
      address: "/services",
    },
    {
      name: "About",
      address: "/about",
    },

    {
      name: "Contact",
      address: "/contact",
    },
  ];
  return (
    <div>
      <div id="welcome">
        <Header menuOptionsProps={menuOptionsProps} />

        <div style={{marginTop: "40px"}}>
          <LinkButton title="Sign In" link="/signin" />
          <LinkButton title="Sign Up" link="/signup" />
        </div>
        <div style={{marginTop: "40px"}}>
          <AndroidLinkButton title="Download Android App" link="https://drive.google.com/file/d/1mA_cHWExUyRiCWHUgdLda8cNGtQpR1UF/view?usp=sharing" />
        </div>

        <div id="greeting_bg">
          {/* <i className="fa-solid fa-ribbon"></i> */}
          <div className="greeting_definition">
            <h1>
              Welcome to <br />
              BeautyOne
            </h1>
            <p>
              A final destination for all kinds of Make Up, Artificial Jewellery
              and Cosmatics. Step into a world where makeup isn't just a routine
              but a transformative experience, where confidence is enhanced and
              individuality celebrated.
            </p>
          </div>
        </div>
        <div id="artist">
          <div>
            <div>Purti Bhawsar</div>
            <div>MakeUp Artist</div>
          </div>
        </div>
        <Footer />
      </div>
      <br />
    </div>
  );
}

export default Welcome;
